import { useRef, useEffect, useState } from "react";
import Parallax from 'parallax-js';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { WidgetInstance } from 'friendly-challenge';
import exclamation from "./resources/exclamation.svg";
import moon from "./resources/moon.png";
import mountain01 from "./resources/mountains01.png";
import mountain02 from "./resources/mountains02.png";
import road from "./resources/road.png";
import bg from "./resources/bg.jpg";
import logo from "./resources/logo.png";
import headerprofile from "./resources/header-profile.png";
import discord from "./resources/discord.svg";
import linkedin from "./resources/linkedin.svg";
import github from "./resources/github.svg";
import cardbg from "./resources/cardbg.jpg";
import cardbggun from "./resources/cardbggun.jpg";
import cardbghappy from "./resources/cardbghappy.jpg";
import cardbgtaxi from "./resources/cardbgtaxi.jpg";
import cardbgmush from "./resources/cardbgmush.jpg";
import cardbggtarp from "./resources/cardbggtarp.jpg";
import cardbgtmd from "./resources/cardbgtmd.jpg";
import vadi from "./resources/vadiinfo.png";
import kartal from "./resources/kartalet.png";
import radio from "./resources/radio.png";
import ghd from "./resources/ghd.webp";
import binkod from "./resources/binkod.png";

function App() {
    const scene = useRef();
    const container = useRef();
    const widget = useRef();
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [sol, setSol] = useState("");
    const [btndisplay, setBtndisplay] = useState("visible");
    const doneCallback = (solution) => {
        setSol(solution);
    }
    const errorCallback = (err) => {
        alert('There was an error when trying to solve the Captcha.\n'+err);
    }
    useEffect(() => {
        const parallaxInstance = new Parallax(scene.current);
    }, [scene]);
    useEffect(() => {
        if (!widget.current && container.current) {
            widget.current = new WidgetInstance(container.current, {
                startMode: "none",
                doneCallback: doneCallback,
                errorCallback: errorCallback
            });
        }
        return () => {
            if (widget.current !== undefined) widget.current.reset();
        }
    }, [container]);
    const sendMail = () => {
        if(email.length>10 && message.length>10 && sol.length > 10){
            setMessage("sending...");
            setEmail("sending...");
            setBtndisplay("hidden");
            const data = new FormData();
            data.append("solution", sol);
            data.append("sender", email);
            data.append("text", message.replaceAll(/\r?\n/g, '|'));
            const requestOptions = {
                method: 'POST',
                body: data
            };
            fetch('https://api.vadi.info/email-to-me', requestOptions)
                .then(async response => {
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const data = isJson && await response.json();
                    if (!response.ok) {
                        const error = (data && data.message) || response.status;
                        return Promise.reject(error);
                    }
                    setBtndisplay("visible");
                    setMessage("");
                    setEmail("");
                    setSol("");
                    alert(data.Message);
                })
                .catch(error => {
                    setBtndisplay("visible");
                    alert(error.toString());
                });
        }else{alert("All fields are mandatory !")}
    }
    return (
        <main>
            <section className="section">
                <div className="container" style={{backgroundImage: `url(${bg})`}}>
                    <div ref={scene}>
                        <div className="layer" data-depth-x="-0.5" data-depth-y="0.25"><img src={moon} className="layerimg" alt="layer1" /></div>
                        <div className="layer" data-depth-x="0.15"><img src={mountain01} className="layerimg" alt="layer2" /></div>
                        <div className="layer" data-depth-x="0.25"><img src={mountain02} className="layerimg" alt="layer3" /></div>
                        <div className="layer" data-depth-x="-0.25"><img src={road} className="layerimg" alt="layer4" /></div>
                    </div>
                </div>
                <PerfectScrollbar className="sitecontent">
                <div className="login">
                    <div className="header">
                        <div style={{flexBasis:'50%'}}>
                            <div className="makecenter">
                                <img src={logo} alt="ismailcetin.dev" />
                            </div>
                            <div className="makecenter textdeveloper">(Software Developer)</div>
                            <div className="makecenter">
                                <img src={headerprofile} alt="ismailcetin.dev" height="196" width="196" />
                            </div>
                        </div>
                        <div style={{flexBasis:'50%',alignSelf:"center"}}>
                            <div className="makecenter textsocial">Social Media</div>
                            <div className="socialcontainer">
                                <a href="https://github.com/icetinturkey" target="_blank" rel="noopener noreferrer" className="social-icons"><img src={github} alt="github" height="32" width="32" /></a>
                                <a href="https://www.linkedin.com/in/ismailce" target="_blank" rel="noopener noreferrer" className="social-icons"><img src={linkedin} alt="linkedin" height="32" width="32" /></a>
                                <a href="https://discordapp.com/users/330277570962784268" target="_blank" rel="noopener noreferrer" className="social-icons"><img src={discord} alt="discord" height="32" width="32" /></a>
                            </div>
                        </div>
                    </div>

                    <div className="mygameprojects">
                        <div className="sectiontexts">Game Projects</div>
                        <div className="cardholder">
                            <a href="https://play.google.com/store/apps/details?id=com.CTNCodeLab.Gunslider" target="_blank" rel="noopener noreferrer">
                                <div className="content">
                                    <div className="makecenter"></div>
                                    <div className="title">Gunslider <i>(mobile)</i></div>
                                </div>
                                <img className="cover" src={cardbggun} alt="article-cover" />
                            </a>
                            <a href="https://globalgamejam.org/games/2024/happy-wanderer-5" target="_blank" rel="noopener noreferrer">
                                <div className="content">
                                    <div className="makecenter"></div>
                                    <div className="title">Happy Wanderer <i>(desktop)</i></div>
                                </div>
                                <img className="cover" src={cardbghappy} alt="article-cover" />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.ctncodelab.toontaxi" target="_blank" rel="noopener noreferrer">
                                <div className="content">
                                    <div className="makecenter"></div>
                                    <div className="title">Toon Taxi <i>(mobile)</i></div>
                                </div>
                                <img className="cover" src={cardbgtaxi} alt="article-cover" />
                            </a>
                            <a href="https://itch.io/jam/jamination-7/rate/2703136" target="_blank" rel="noopener noreferrer">
                                <div className="content">
                                    <div className="makecenter"></div>
                                    <div className="title">Mush <i>(web)</i></div>
                                </div>
                                <img className="cover" src={cardbgmush} alt="article-cover" />
                            </a>
                            <a href="https://itch.io/jam/bogazici-game-jam24/rate/2717661" target="_blank" rel="noopener noreferrer">
                                <div className="content">
                                    <div className="makecenter"></div>
                                    <div className="title">Too Many Doors <i>(web)</i></div>
                                </div>
                                <img className="cover" src={cardbgtmd} alt="article-cover" />
                            </a>
                            <a href="https://servers.fivem.net/servers/detail/l36y84" target="_blank" rel="noopener noreferrer">
                                <div className="content">
                                    <div className="makecenter"></div>
                                    <div className="title">Parazit RP <i>(fivem)</i></div>
                                </div>
                                <img className="cover" src={cardbggtarp} alt="article-cover" />
                            </a>
                        </div>
                    </div>

                    <div className="mywebprojects">
                        <div className="sectiontexts">Web Projects</div>
                        <div className="cardholder">
                            <a href="https://vadi.info" target="_blank" rel="noopener noreferrer">
                                <div className="content">
                                    <div className="makecenter">
                                    <img src={vadi} alt="vadiinfo" height="80" />
                                    </div>
                                    <div className="title">Vadi.Info <i>(blog)</i></div>
                                </div>
                                <img className="cover" src={cardbg} alt="article-cover" />
                            </a>
                            <a href="https://radyo.vadi.info" target="_blank" rel="noopener noreferrer">
                                <div className="content">
                                    <div className="makecenter">
                                    <img src={radio} alt="vadiradio" height="80" />
                                    </div>
                                    <div className="title">Radio Vadi <i>(web app)</i></div>
                                </div>
                                <img className="cover" src={cardbg} alt="article-cover" />
                            </a>
                            <a href="https://gecmishavadurumu.com.tr" target="_blank" rel="noopener noreferrer">
                                <div className="content">
                                    <div className="makecenter">
                                    <img src={ghd} alt="gecmishavadurumu" height="80" />
                                    </div>
                                    <div className="title">GecmisHavaDurumu <i>(landing)</i></div>
                                </div>
                                <img className="cover" src={cardbg} alt="article-cover" />
                            </a>
                            <a href="https://kartaletmamulleri.com" target="_blank" rel="noopener noreferrer">
                                <div className="content">
                                    <div className="makecenter">
                                    <img src={kartal} alt="kartaletmamulleri" height="80" />
                                    </div>
                                    <div className="title">Kartal Et <i>(landing)</i></div>
                                </div>
                                <img className="cover" src={cardbg} alt="article-cover" />
                            </a>
                            <a href="https://ecommerce.ismailcetin.dev" target="_blank" rel="noopener noreferrer">
                                <div className="content">
                                    <div className="makecenter">
                                        <img src={binkod} alt="binkodecommerce" height="80" />
                                    </div>
                                    <div className="title">Binkod <i>(e-commerce)</i></div>
                                </div>
                                <img className="cover" src={cardbg} alt="article-cover" />
                            </a>
                        </div>
                    </div>

                    <div className="contactcontainer">
                    <div className="leftdiv">
                        <div>
                            <div className="mainlogo"></div>
                            <div className="dangerlogo"><img src={exclamation} alt="warning" /></div>
                            <div className="dangertxt"><p>Here is currently under construction.</p></div>
                        </div>
                    </div>
                    <div>
                        <p style={{fontSize:"18px"}}>Contact Me</p>
                        <input name="email" type="text" className="feedbackinput" placeholder="Your Email" value={email} onChange={event => setEmail(event.target.value)} />
                        <textarea name="text" className="feedbackinput" placeholder="Your Message" value={message} onChange={event => setMessage(event.target.value)}></textarea>
                        <div ref={container} className="frc-captcha" data-sitekey="FCMQL6QA0QDJV426" />
                        <input type="button" value="Send" onClick={sendMail} className="feedbackbutton" style={{visibility:btndisplay}} />
                    </div>
                    </div>
                </div>
                </PerfectScrollbar>
            </section>
        </main>
    );
}

export default App;
